import { useState, useRef, useEffect } from "react";
import "./DayPicker.less";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

/**
 * Extended version of the AirBnB daypicker with our UI customization added
 *  */

interface DayPickerI {
  ariaLabel?: string;
  date?: moment.Moment;
  disabled?: boolean;
  id: string;
  isOutsideRange?: (date: moment.Moment) => boolean;
  onDateChange: (date: moment.Moment) => void;
  placeholder?: string;
  showClearDate?: boolean;
}

const DayPicker = ({
  ariaLabel,
  date,
  disabled,
  id,
  isOutsideRange,
  onDateChange,
  placeholder = "Select Date",
  showClearDate,
}: DayPickerI) => {
  const [focused, setFocused] = useState(false);
  const focusedRef = useRef(false);
  const containerRef = useRef(null);
  const checkRange = isOutsideRange ? { isOutsideRange } : {};

  useEffect(() => {
    function listener({ key }) {
      if (focusedRef.current && key === "Tab") {
        const el = document.getElementById(id) as HTMLElement;
        if (el) {
          el.focus();
        }
        setFocus({ focused: false });
      }
    }
    if (focused) {
      window.addEventListener("keydown", listener, true);
      focusOnDate();
    } else {
      window.removeEventListener("keydown", listener);
    }
    return () => window.removeEventListener("keydown", listener);
  }, [ariaLabel, focused]);

  function setFocus({ focused }) {
    setFocused(focused);
    focusedRef.current = focused;
  }

  function setFocusOnButton() {
    setTimeout(() => {
      const target = containerRef.current.getElementsByClassName(
        "SingleDatePickerInput_calendarIcon"
      )[0] as HTMLButtonElement;

      target?.focus();
    }, 250);
  }

  function focusOnDate() {
    setTimeout(() => {
      const days = document.getElementsByClassName("CalendarDay") || [];
      let el;
      for (let i = 0; i < days.length; i++) {
        if (days[i].tabIndex === 0) {
          el = days[i];
          break;
        }
      }
      if (el) {
        el.focus();
      }
    }, 250);
  }

  const renderMonthElement = ({ month, onYearSelect }) => {
    return (
      <div className="monthContainer">
        <Icon
          onClick={() => onYearSelect(month, month.year() - 1)}
          name="angle double left"
          className="caret prevYear"
        />
        <strong>{`${month.format("MMMM")} ${month.year()}`}</strong>
        <Icon
          onClick={() => onYearSelect(month, month.year() + 1)}
          name="angle double right"
          className="caret nextYear"
        />
      </div>
    );
  };

  return (
    <div className="day-picker" ref={containerRef}>
      <SingleDatePicker
        {...checkRange}
        ariaLabel={ariaLabel}
        hideKeyboardShortcutsPanel={true}
        displayFormat="DD MMM, YYYY"
        disabled={disabled}
        withPortal
        navNext={<Icon name="caret right" className="caret" />}
        navPrev={<Icon name="caret left" className="caret" />}
        renderMonthElement={renderMonthElement}
        numberOfMonths={2}
        placeholder={placeholder}
        showDefaultInputIcon
        date={date}
        onDateChange={(date) => {
          onDateChange(date);
          setFocusOnButton();
        }}
        focused={focused}
        onFocusChange={setFocus}
        id={id}
        showClearDate={showClearDate}
      />
    </div>
  );
};

DayPicker.propTypes = {
  /** Current date value for the controlled daypicker component */
  date: PropTypes.object,
  /** Disables date picker */
  disabled: PropTypes.bool,
  /** Callback function that allows you to control which dates are acceptable */
  isOutsideRange: PropTypes.func,
  /** Callback function to update date value */
  onDateChange: PropTypes.func.isRequired,
};

DayPicker.defaultProps = {
  disabled: false,
};

export default DayPicker;
